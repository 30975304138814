<template>
  <dashboard-layout>
    <div class="my-5 py-5 overflow-hidden">
      <div class="row justify-content-center">
        <div class="col-10">
          <admin-statistics title="User" :stats="stats"></admin-statistics>
          <div class="py-5">
            <div class="pb-3">
              <div class="row">
                <div class="col d-flex">
                  <div class="dropdown">
                    <button class="btn button" type="button" data-bs-toggle="dropdown" >
                      <i class="bi bi-filter fs-3 me-3 text-primary"></i>
                    </button>
                    <ul class="dropdown-menu px-3">
                      <div class="form-check">
                        <li>
                          <div @click="all_users=true">
                            <input @click="fetchAllUsers" class="form-check-input" type="radio" name="users" checked>
                          </div>
                          <label class="form-check-label">
                            All users
                          </label>
                        </li>
                        <li>
                          <div @click="all_users=false">
                            <input @click="fetchVerifiedUsers" class="form-check-input" type="radio" name="users">
                          </div>
                          <label class="form-check-label">
                            Verified users
                          </label>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div class="d-flex align-items-center search-bar px-2 ">
                    <i class="bi bi-search fs-4"></i>
                    <input
                      type="text"
                      class="search-box py-2 px-2"
                      placeholder="Search.."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white">
              <user-table
                :users="users"
              ></user-table>
              <pagination
                v-if="all_users"
                :page="page"
                :onSelectPerPage="onSelectPerPage"
                :load-prev-page="loadPrevPage"
                :load-next-page="loadNextPage"
              ></pagination>
              <pagination
                v-else
                :page="page"
                :onSelectPerPage="onSelectVerifiedPerPage"
                :load-prev-page="loadPrevVerifiedPage"
                :load-next-page="loadNextVerifiedPage"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { mapState, mapActions } from "vuex"
import DashboardLayout from "@/layouts/Dashboard.vue"
import Pagination from "@/components/dashboard/Pagination.vue"
import AdminStatistics from "@/components/dashboard/AdminStatistics.vue"
import UserTable from "@/components/dashboard/UserTable.vue";

export default {
  name: "DashboardUser",
  components: {
    UserTable,
    DashboardLayout,
    Pagination,
    AdminStatistics
  },
  data() {
    return {
      stats: null,
      all_users: true
    }
  },
  async mounted() {
    await this.getUserStats()
    await this.getUsers({ per_page: this.page.per_page, page: 1 })
  },
  computed: {
    ...mapState("user", ["users", "page", "user_stats"])
  },
  methods: {
    ...mapActions("user", ["getUsers", "deleteUser", "getUserStats", "getVerifiedUsers",]),
    fetchAllUsers(){
      this.getUsers({ per_page: this.page.per_page, page: 1 })
    },
    fetchVerifiedUsers(){
      this.getVerifiedUsers({ per_page: this.page.per_page, page: 1 })
    },
    loadNextPage() {
      this.getUsers({
        per_page: this.page.per_page,
        page: this.page.current_page + 1
      })
    },
    loadPrevPage() {
      this.getUsers({
        per_page: this.page.per_page,
        page: this.page.current_page - 1
      })
    },
    onSelectPerPage(event) {
      const perPage = event.target.value
      this.getUsers({
        per_page: perPage,
        page: this.page.current_page
      })
    },
    loadNextVerifiedPage() {
      this.getVerifiedUsers({
        per_page: this.page.per_page,
        page: this.page.current_page + 1
      })
    },
    loadPrevVerifiedPage() {
      this.getVerifiedUsers({
        per_page: this.page.per_page,
        page: this.page.current_page - 1
      })
    },
    onSelectVerifiedPerPage(event) {
      const perPage = event.target.value
      this.getVerifiedUsers({
        per_page: perPage,
        page: this.page.current_page
      })
    }
  },
  watch: {
    user_stats(val) {
      this.stats = {
        total: val.users_count,
        today: val.users_today,
        this_week: val.users_this_week,
        this_month: val.users_this_month,
        this_year: val.users_this_year
      }
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  min-width:200px;
}
.button {
  cursor: pointer;
  border: none;
}
.search-bar {
  background: #dae1ec;
  border-radius: 5px;
  width: 23rem;
}
.search-box {
  background: #dae1ec;
  border: none;
  width: 28rem;
  outline: none;
}
</style>
