<template>
  <!-- admin user dashboard table component to view all users -->
  <div class="table-responsive">
    <table class="table text-start align-middle table-borderless table-hover">
      <thead>
        <tr>
          <th class="th">
            <input
              type="checkbox"
              class="form-check-input me-3 fs-5"
            />
            NAME
          </th>
          <th class="th">GENDER</th>
          <th class="th">DATE OF BIRTH</th>
          <th class="th">VERIFIED</th>
          <th class="th">JOINED</th>
          <th class="th">ACTIONS</th>
        </tr>
      </thead>
      <tbody
        v-for="user in users"
        :key="user.id"
      >
        <tr class="">
          <td>
            <input
              type="checkbox"
              class="form-check-input me-3 fs-5 align-top"
            />
            {{ user.first_name }} {{ user.last_name }}
            <i
              class="bi bi-patch-check-fill text-success ms-1"
              v-if="user.email_verified_at != null"
            ></i>
          </td>
          <td>{{ user.gender }}</td>
          <td>{{ user.dob }}</td>
          <td>{{ user.email_verified_at }}</td>
          <td>{{ user.created_at }}</td>
          <td>
            <div class="col-md-2">
              <div class="d-flex">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="modal"
                  :data-bs-target="'#viewUser' + user.id"
                >
                  <i class="bi bi-eye-fill fs-5"></i>
                </button>
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="modal"
                  :data-bs-target="'#userDeleteModal' + user.id"
                >
                  <i class="bi bi-trash-fill fs-5"></i>
                </button>
              </div>
            </div>
            <user-detail-modal :user="user"></user-detail-modal>
            <user-delete-modal :user_id="user.id"></user-delete-modal>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UserDeleteModal from "@/components/user/UserDeleteModal.vue";
import UserDetailModal from "@/components/dashboard/UserDetailModal.vue";

export default {
  name: "UserTable",
  components: {UserDetailModal, UserDeleteModal},
  props: {
    users: Array
  }
}
</script>

<style scoped>
.table-hover tbody tr:hover td {
  background: #FFE3F7;
}
.bi-trash-fill {
  color: #7d90b2;
}
.bi-trash-fill:hover {
  color: #4f4f4f;
}
.bi-eye-fill {
  color: #7d90b2;
}
.bi-eye-fill:hover {
  color: #4f4f4f;
}
.th {
  background-color: #F4F7FC;
  color: #2E3B52;
}
</style>