<template>
  <div
    class="modal fade"
    :id="'viewUser' + user.id"
    tabindex="-1"
    aria-labelledby="viewUser"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="p-3">
          <div class="d-flex justify-contents-between align-items-center">
            <button type="button" class="btn" data-bs-dismiss="modal">
              <i class="bi bi-arrow-left"></i>
            </button>
          </div>
        </div>
        <div class="modal-body p-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column border-end pe-3">
              <img
                v-if="user"
                :src="
                  user.avatar
                    ? user.avatar
                    : 'http://www.gravatar.com/avatar'
                "
                class="user-img"
                alt="user-image"
              />
              <button
                class="btn btn-primary mt-3"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="'#userDeleteModal' + user.id"
              >
                <span class="text-white">Delete</span>
                <span class="ms-1">
                  <i
                    class="bi bi-trash-fill text-white"
                    style="color: #7d90b2"
                  ></i>
                </span>
              </button>
            </div>
            <div class="">
              <div class="">
                <h2 class="user-placeholder">Name:</h2>
                <h2 class="user-name">
                  {{ user.first_name }} {{ user.last_name }}
                  <i
                    class="bi bi-patch-check-fill text-success ms-1"
                    v-if="user.email_verified_at != null"
                  ></i>
                </h2>
              </div>
              <div class="">
                <h2 class="user-placeholder">Gender:</h2>
                <h2 class="user-name">{{ user.gender }}</h2>
              </div>
              <div class="d-flex">
                <div>
                  <h2 class="user-placeholder">Date of birth:</h2>
                  <h2 class="user-name">{{ user.dob }}</h2>
                </div>
                <div class="ms-3">
                  <h2 class="user-placeholder">Joined:</h2>
                  <h2 class="user-name">
                    <span>{{ moment(user.created_at).fromNow() }} </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: "UserDetailModal",
  props: { user: Object },
  created() {
    this.moment = moment
  }
}
</script>

<style scoped>
.user-img {
  width: 192px;
  height: 192px;
  border-radius: 100px;
}
.modal-dialog {
  width: 662px;
  height: 455px;
}
.user-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;
}
.user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #04295f;
}
</style>
